import NavbarServices from '../../components/Navbar/NavbarServices';
import './styles.css';
import fsc from '../../assets/icons/fsc.png';
import oekotex from '../../assets/icons/oekotex.png';
import gots from '../../assets/icons/gots.png';
import grs from '../../assets/icons/grs.png';
import logo_letna from '../../assets/images/Logo_letna.png';
import React, { useState } from 'react';

const AboutUs = () => {

  return (
    <>
    <NavbarServices />
    
      <div className='container-aboutus'>
        {/*<img  className="Caroussel" src={imageaboutus} alt="LABEL & PACK" loading="lazy"></img>*/}
      </div>
      <div className='margens-services'>

      <div class="about-us-section">
        <h1 class="AboutUs_Title">About Us</h1>
        <p class="intro">
            LETNA GROUP is an innovative leader in the textile and packaging industry based in Portugal. 
            United by a common vision of excellence and innovation, we have merged the industrial capabilities of our two production sites: 
            <strong> LETNA LDA</strong>, specializing in textile manufacturing, and <strong>MULTILABEL SA</strong>, specializing in labeling and packaging, 
            to offer a unique, integrated solution for the promotional item and merchandising market.
        </p>

        <p class="AboutUs_Desc">
            Now operating under one roof, we can handle the most complex merchandising projects with maximum agility by offering tailor-made solutions 
            that enhance product presentation, strengthen brand image, and energize the customer experience.
        </p>

        <p class="AboutUs_Desc">
            By combining our complementary skills, we are proud to offer an unparalleled joint service to lifestyle, cosmetics, fashion, 
            and hospitality brands, among others. From design to manufacturing, we are your trusted partner for creating various promotional 
            items <strong>MADE IN PORTUGAL</strong>, ensuring quality, adaptability, and efficiency at every stage of the process.
        </p>

        <p class="AboutUs_Desc" style={{marginBottom:'50px'}}>
            We look forward to supporting young companies in their merchandising projects, even in small quantities, while maintaining the capacity 
            and flexibility needed to handle tenders and projects exceeding 200,000 pieces. Our flexibility reflects your requirements.
        </p>
      </div>
    </div>

    <footer className='footer'>
        <div className='margens'>
          <div className='row-1'>
            <div className='páginas site-links'>
              <h4>SITE LINKS</h4>
              <a href="#home">Home</a>
              <a href="#services">Services</a>
              <a href="#about">About Us</a>
              <a href="#contact">Contacts</a>
            </div>
            <div className='páginas'>
              <p>Av. da Ponte Nova N° 451, <br/>4770-414 Pousada de Saramagos<br/>V.N. Famalicão | Portugal</p>   
              
            </div>
            <div className='páginas'>
              <p>+351 253 131 515<br/>comercial@letna.com<br/>www.letna.pt</p>           
              
            </div>
            <div className='logos'>
                <img src={logo_letna} alt="Logo" style={{width:'150px', alignItems:'center', justifyContent:'center'}}/>
            </div>
          </div>
          <hr></hr>
          <div className='certifications'>
              <p>Certifications</p>
              <img  className='certificado' src={fsc} alt="ABOUT US" loading="lazy"></img>
              <img  className='certificado' src={oekotex} alt="ABOUT US" loading="lazy"></img>
              <img  className='certificado' src={gots} alt="ABOUT US" loading="lazy"></img>
              <img  className='certificado' src={grs} alt="ABOUT US" loading="lazy"></img>
          </div>
          <div className='row2'>
            <p>Copyright © 2024 Todos os direitos reservados | Política de privacidade</p>
          </div>
        </div>
      </footer>

    </>
  );
};

export default AboutUs;
