import NavbarServices from '../../components/Navbar/NavbarServices';
import '../textile/styles.css';
import image1 from '../../assets/images/textile-img1.jpg';
import image2 from '../../assets/images/textile-img2.jpg';
import image3 from '../../assets/images/textile-img3.jpg';
import image4 from '../../assets/images/textile-img4.jpg';
import image5 from '../../assets/images/textile-img5.jpg';
import image6 from '../../assets/images/textile-img6.jpg';
import image7 from '../../assets/images/textile-img7.jpg';
import image8 from '../../assets/images/textile-img8.jpg';
import image9 from '../../assets/images/textile-img9.jpg';
import fsc from '../../assets/icons/fsc.png';
import oekotex from '../../assets/icons/oekotex.png';
import gots from '../../assets/icons/gots.png';
import grs from '../../assets/icons/grs.png';
import logo_letna from '../../assets/images/Logo_letna.png';
import Modal from '../../components/Modal/Modal';
import React, { useState } from 'react';

const Textile = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');

  const images = [
    { src: image1, project: 'Net totebag', description: 'Summer Collection' },
    { src: image2, project: 'Beauty pouches', description: 'Summer Collection' },
    { src: image3, project: 'Pouch & Accessories', description: 'Beauty Collection' },
    { src: image4, project: 'Plush pouch', description: 'Winter collection' },
    { src: image5, project: 'Glitter pouches', description: 'Christmas collection' },
    { src: image6, project: 'Moon shape & plaid', description: 'Night Collection' },
    { src: image7, project: 'Scrunchies', description: 'Beauty collection' },
    { src: image8, project: 'Frill totebag', description: 'Summer Collection' },
    { src: image9, project: 'Eye mask and hair accessories', description: 'Night Collection' },
  ];

  const openModal = (image) => {
    
    setSelectedImage(image);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedImage('');
  };

  return (
    <>
      <NavbarServices />
      <div className='margens-services'>
        <div> 
          <div className='card-content'>
            <div className='row-1'>
              {images.map((image, index) => (
                <div className='card-image' key={index} onClick={() => openModal(image.src)}>
                  <img src={image.src} alt={`Imagem ${index + 1}`}  />
                  <div className='description'>
                    <h3>{image.project}</h3>
                    <p>{image.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        
        <div class="textile-section">
          <h1 class="title">TEXTILE</h1>
          <p class="intro">
              We offer a wide range of textile accessories, from pouches to customized bags, including computer covers and other cotton products. Our internal team of stylists and designers is ready to produce swiftly and adapt to your brand's DNA.
          </p>

          <h2 class="subtitle">Accessories Produced:</h2>
          <ul class="accessory-list">
              <li>Totebags</li>
              <li>Pouches</li>
              <li>Coin Holders</li>
              <li>Cotton Pads</li>
              <li>Shopping Bags</li>
              <li>Keychains</li>
              <li>Beach Towels</li>
          </ul>

          <h2 class="subtitle">Our Strengths:</h2>
          <p class="description1">
              Our commitment is to create uniforms and garments that not only meet functional needs but also reflect the identity and values of our clients.
          </p>

          <h2 class="subtitle">Customization:</h2>
          <p class="description1" style={{marginBottom:'50px'}}>
              We utilize advanced techniques such as embroidery, screen printing, and transfer to meet any request with speed and precision.
          </p>
        </div>

      </div>

      <footer className='footer'>
        <div className='margens'>
          <div className='row-1'>
            <div className='páginas site-links'>
              <h4>SITE LINKS</h4>
              <a href="#home">Home</a>
              <a href="#services">Services</a>
              <a href="#about">About Us</a>
              <a href="#contact">Contacts</a>
            </div>
            <div className='páginas'>
              <p>Av. da Ponte Nova N° 451, <br/>4770-414 Pousada de Saramagos<br/>V.N. Famalicão | Portugal</p>   
              
            </div>
            <div className='páginas'>
              <p>+351 253 131 515<br/>comercial@letna.com<br/>www.letna.pt</p>           
              
            </div>
            <div className='logos'>
                <img src={logo_letna} alt="Logo" style={{width:'150px', alignItems:'center', justifyContent:'center'}}/>
            </div>
          </div>
          <hr></hr>
          <div className='certifications'>
              <p>Certifications</p>
              <img  className='certificado' src={fsc} alt="ABOUT US" loading="lazy"></img>
              <img  className='certificado' src={oekotex} alt="ABOUT US" loading="lazy"></img>
              <img  className='certificado' src={gots} alt="ABOUT US" loading="lazy"></img>
              <img  className='certificado' src={grs} alt="ABOUT US" loading="lazy"></img>
          </div>
          <div className='row2'>
            <p>Copyright © 2024 Todos os direitos reservados | Política de privacidade</p>
          </div>
        </div>
      </footer>      

      <Modal isOpen={isModalOpen} onClose={closeModal} image={selectedImage} />
    </>
  );
};

export default Textile;
