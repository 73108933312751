import NavbarServices from '../../components/Navbar/NavbarServices';
import '../textile/styles.css';
import image1 from '../../assets/images/sourcing-img1.jpg';
import image2 from '../../assets/images/sourcing-img2.jpg';
import image3 from '../../assets/images/sourcing-img3.jpg';
import fsc from '../../assets/icons/fsc.png';
import oekotex from '../../assets/icons/oekotex.png';
import gots from '../../assets/icons/gots.png';
import grs from '../../assets/icons/grs.png';
import logo_letna from '../../assets/images/Logo_letna.png';
import Modal from '../../components/Modal/Modal';
import React, { useState } from 'react';


const Sourcing = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');

  const images = [
    { src: image1, project: 'Step 1', description: 'Brainstorming and Briefing' },
    { src: image2, project: 'Step 2', description: 'Sourcing of raw materials' },
    { src: image3, project: 'Step 3', description: 'Production follow up' },
  ];

  const openModal = (image) => {
    
    setSelectedImage(image);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedImage('');
  };

  return (
    <>
      <NavbarServices />
      <div className='margens-services'>
        <div>
          <div className='card-content'>
            <div className='row-1'>
              {images.map((image, index) => (
                <div className='card-image' key={index} onClick={() => openModal(image.src)}>
                  <img src={image.src} alt={`Imagem ${index + 1}`}  />
                  <div className='description'>
                    <h3>{image.project}</h3>
                    <p>{image.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div class="sourcing-section">
          <h1 class="title">SOURCING</h1>
          <p class="intro">
              In order to be innovative and competitive, we regularly conduct sourcing with our historical partners for raw materials 
              and beyond the borders of Portugal.
          </p>
          
          <p class="description1">
              Over the years, we have built strong relationships of trust with our neighbors and surrounding factories. This means that, 
              in addition to the products we manufacture in-house, your project manager can assist you in sourcing complementary products 
              through our network of partner factories.
          </p>
          
          <p class="description1">
              No production will be undertaken outside our facilities without your prior approval.
          </p>
          
          <p class="description1" style={{marginBottom:'50px'}}>
              Northern Portugal is a rich industrial hub where we can find a variety of companies specializing in ceramics, wood, leather, 
              shoes, and much more. Feel free to ask us!
          </p>
        </div>

      </div>

      <footer className='footer'>
        <div className='margens'>
          <div className='row-1'>
            <div className='páginas site-links'>
              <h4>SITE LINKS</h4>
              <a href="#home">Home</a>
              <a href="#services">Services</a>
              <a href="#about">About Us</a>
              <a href="#contact">Contacts</a>
            </div>
            <div className='páginas'>
              <p>Av. da Ponte Nova N° 451, <br/>4770-414 Pousada de Saramagos<br/>V.N. Famalicão | Portugal</p>   
              
            </div>
            <div className='páginas'>
              <p>+351 253 131 515<br/>comercial@letna.com<br/>www.letna.pt</p>           
              
            </div>
            <div className='logos'>
                <img src={logo_letna} alt="Logo" style={{width:'150px', alignItems:'center', justifyContent:'center'}}/>
            </div>
          </div>
          <hr></hr>
          <div className='certifications'>
              <p>Certifications</p>
              <img  className='certificado' src={fsc} alt="ABOUT US" loading="lazy"></img>
              <img  className='certificado' src={oekotex} alt="ABOUT US" loading="lazy"></img>
              <img  className='certificado' src={gots} alt="ABOUT US" loading="lazy"></img>
              <img  className='certificado' src={grs} alt="ABOUT US" loading="lazy"></img>
          </div>
          <div className='row2'>
            <p>Copyright © 2024 Todos os direitos reservados | Política de privacidade</p>
          </div>
        </div>
      </footer>

      <Modal isOpen={isModalOpen} onClose={closeModal} image={selectedImage} />
    </>
    );
};



export default Sourcing;