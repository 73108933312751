import React from 'react';
import './styles.css';
import logo_letna from '../../assets/images/Logo_letna.png';
import { Link } from 'react-router-dom';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

function NavbarServices() {
  return (

    <header>
      <nav className='nav-bar-services'> 
     
        <Link to="/" >
            <img src={logo_letna} className="nav_logo"/>
        </Link>
     
      </nav>
    </header>
    
  );   
}

export default NavbarServices;