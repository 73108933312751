import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const scrollPositions = {};

function ScrollManager() {
  const { pathname } = useLocation();

  useEffect(() => {
    // Salva a posição de rolagem antes de navegar
    return () => {
      scrollPositions[pathname] = window.scrollY;
    };
  }, [pathname]);

  useEffect(() => {
    // Restaura a posição de rolagem ao voltar para uma página
    if (scrollPositions[pathname] !== undefined) {
      window.scrollTo(0, scrollPositions[pathname]);
    } else {
      window.scrollTo(0, 0); // Scroll para o topo se não há posição salva
    }
  }, [pathname]);

  return null;
}

export default ScrollManager;