import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import the CSS for the carousel
import { Carousel } from 'react-responsive-carousel';
import carroselimg1 from '../../assets/images/carrosel-img1.jpg';
import carroselimg2 from '../../assets/images/carrosel-img2.jpg';

const CarouselTest = () => {
    return (
      <div className='container'>
        <Carousel autoPlay infiniteLoop showThumbs={false} showStatus={false} showArrows={false}>
            <div>
                <img id='img1' src={carroselimg1} alt="First slide" />               
            </div>
            <div>
                <img id='img2' src={carroselimg2} alt="Second slide" />              
            </div>
        </Carousel>
        </div>
    );
}

export default CarouselTest;
