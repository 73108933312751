import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Mainpage from './pages/mainpage/Mainpage';
import Textile from './pages/textile/Textile';
import Labelandpack from './pages/label&pack/Label&pack';
import Sourcing from './pages/sourcing/Sourcing';
import ScrollManager from './components/ScrollToTop/Scroll';
import AboutUs from './pages/aboutus/AboutUs';

function App() {
  return (
    <Router>
      <ScrollManager />
      <Routes>
        <Route path="/" element={<Mainpage />} />
        <Route path="/textile" element={<Textile />} />
        <Route path="/label&pack" element={<Labelandpack />} />
        <Route path="/sourcing" element={<Sourcing />} />
        <Route path="/aboutus" element={<AboutUs />} />
      </Routes>
    </Router>
  );
}

export default App;
