import Navbar from '../../components/Navbar/Navbar';
import Carousel from '../../components/Carousel/Carousel';
import logo_letna from '../../assets/images/Logo_letna.png';
import imageaboutus from '../../assets/images/image-aboutus.jpg';
import criativity from '../../assets/icons/innovation.png';
import provision from '../../assets/icons/provision.png';
import quality from '../../assets/icons/quality.png';
import time from '../../assets/icons/stopwatch.png';
import instagram from '../../assets/icons/instagram.png';
import linkedin from '../../assets/icons/linkedin.png';
import fsc from '../../assets/icons/fsc.png';
import oekotex from '../../assets/icons/oekotex.png';
import gots from '../../assets/icons/gots.png';
import grs from '../../assets/icons/grs.png';
import { Link } from 'react-router-dom';
import './styles.css';
import MapComponent from '../../components/MapComponent/MapComponent';
import Carousel1 from '../../components/Carousel/CarouselTest';



const Mainpage = () => {
    return (
    <div className='main'>
      <Navbar />
      <div className='container-1' id='home'>
        <div className='margens'>
          <Carousel1 />
        </div>
      </div>
      <div className='container-2' id='services'>
        <div className='margens'>
          <div className='services-cards-wrapper'>
            <div className='service-card'>
              <div className='card-img' id='img-textile'>
                <Link to="/textile">
                  <div className='card-description'>
                    <p>Textile</p>
                  </div>
                </Link>                
              </div>
            </div>
            <div className='service-card'>
              <div className='card-img' id='img-labelpack'>  
                <Link to="/label&pack">
                    <div className='card-description'>
                      <p>Label & Pack</p>
                    </div>
                </Link>
              </div>
            </div>  
            <div className='service-card'>
              <div className='card-img' id='img-sourcing'>
                <Link to="/sourcing">
                      <div className='card-description'>
                        <p>Sourcing</p>
                      </div>
                </Link>                
              </div>
            </div>
          </div>
        </div>      
      </div>
      <div className='container-3' id='about'>
          <div className='margens'>
              <div className='about-us-left'>
                  <span className='about-us'>About Us</span>
                  <p className='about-us-text'>LETNA GROUP is an innovative leader in the textile and packaging industry based in Portugal. Your demands allow us to propose innovative solutions that reinforce your brand image and enhance your customer experience. Our flexibility reflects your requirements.</p>
                  <Link to="/aboutus"><div class="container-name"><a class="example_f" href="#" target="_blank" rel="nofollow"><span>See More</span></a></div></Link>
                  <div className='about-us-pontos-fortes'>
                  <div className='icon-text'>
                    <img className='icon' src={criativity} alt="criativity" loading="lazy"></img>
                    <p>Innovation</p>
                  </div>
                  <div className='icon-text'>
                    <img className='icon' src={provision} alt="provision" loading="lazy"></img>
                    <p>Solution Oriented</p>
                  </div>
                  <div className='icon-text'>
                    <img className='icon' src={quality} alt="quality" loading="lazy"></img>
                    <p>Certficats</p>
                  </div>
                  <div className='icon-text'>
                    <img className='icon' src={time} alt="time" loading="lazy"></img>
                    <p>Direct Usine</p>
                  </div>
                </div>
              </div>
              <div className='about-us-right'>
                <img  className='img-empresa' src={imageaboutus} alt="ABOUT US" loading="lazy"></img>
              </div>
          </div>
      </div>
      {/*<div className='container-4' id='certifications'>
        <div className='margens'>
          <div className='certifications'>
            <p>Certifications</p>
            <img  className='certificado' src={grs} alt="ABOUT US" loading="lazy"></img>
            <img  className='certificado' src={oekotex} alt="ABOUT US" loading="lazy"></img>
            <img  className='certificado' src={gots} alt="ABOUT US" loading="lazy"></img>
            <img  className='certificado' src={grs} alt="ABOUT US" loading="lazy"></img>
          </div>
        </div>
      </div>*/}
      <div className='container-4' id='contact'>
        <div className='margens' >
          <div className='contacts'><p>Contacts</p></div>
          <div className='contacts-main'>
            <div className='contacts-esq'>
              <div  className='mapa'><MapComponent /></div>
            </div>
            <div className='contacts-dir'>
              <div className='cont'>
                <h2>ADDRESS:</h2>
                <p>Av. da Ponte Nova N° 451, <br/>4770-414 Pousada de Saramagos</p>
              </div>
              <div className='cont'>
                <h2>SERVICE HOURS:</h2>
                <p>08:30H - 18.30H <br/> Segunda - Sexta</p>
              </div>
              <div className='cont'>
                <h2>EMAIL:</h2>
                <p>comercial@letna.com</p>
              </div>
              <div className='cont'>
                <h2>SOCIAL:</h2>
                <div className='social-media'>
                  <img className='icon-social' src={instagram}></img>
                  <img className='icon-social' src={linkedin}></img>             
                </div>
              </div>             
            </div>
          </div>
        </div>
      </div>
      <footer className='footer'>
        <div className='margens'>
          <div className='row-1'>
            <div className='páginas site-links'>
              <h4>SITE LINKS</h4>
              <a href="#home">Home</a>
              <a href="#services">Services</a>
              <a href="#about">About Us</a>
              <a href="#contact">Contacts</a>
            </div>
            <div className='páginas'>
              <p>Av. da Ponte Nova N° 451, <br/>4770-414 Pousada de Saramagos<br/>V.N. Famalicão | Portugal</p>   
              
            </div>
            <div className='páginas'>
              <p>+351 253 131 515<br/>comercial@letna.com<br/>www.letna.pt</p>           
              
            </div>
            <div className='logos'>
                <img src={logo_letna} alt="Logo" style={{width:'150px', alignItems:'center', justifyContent:'center'}}/>
            </div>
          </div>
          <hr></hr>
          <div className='certifications'>
              <p>Certifications</p>
              <img  className='certificado' src={fsc} alt="ABOUT US" loading="lazy"></img>
              <img  className='certificado' src={oekotex} alt="ABOUT US" loading="lazy"></img>
              <img  className='certificado' src={gots} alt="ABOUT US" loading="lazy"></img>
              <img  className='certificado' src={grs} alt="ABOUT US" loading="lazy"></img>
          </div>
          <div className='row2'>
            <p>Copyright © 2024 Todos os direitos reservados | Política de privacidade</p>
          </div>
        </div>
      </footer>
    </div>
    );
};

export default Mainpage;