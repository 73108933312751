import React from 'react';

const MapComponent = () => {
  return (
    <div style={{ width: '100%', maxWidth: '700px', margin: '0' }}>
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2991.815269354663!2d-8.432051491100085!3d41.42152857635074!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd24f7f3ca674b9b%3A0xa6e909b4bdb64b07!2sLETNA%20LDA!5e0!3m2!1spt-PT!2spt!4v1725463603996!5m2!1spt-PT!2spt" width="100%" height="400" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" style={{border: '0'}}></iframe>
    </div>
  );
};

export default MapComponent;